.product-page {
  background-color: var(--theme-background);
  width: 1200px;
  min-height: 600px;
  margin: 0 auto;
  overflow: hidden;
}

.product-page .productCardGroupTitle {
  font-size: 2rem;
  color: var(--theme-dark);
  margin-bottom: 1rem;
}

.info-bar{
  display: flex;
  flex-direction: row;
  /*padding: 12px;*/
  margin: 12px;
}

.info-bar span{
  text-align: left;
}

.search-filter-menu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-form {
  display: flex;
  justify-content: start;
  padding: 10px;
}

.filter-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filter-select {
  height: 35px;
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  cursor: pointer;
}

.filter-select:focus {
  border-color: #007bff;
  outline: none;
}

.filter-submit {
  height: 35px;
  padding: 5px 15px;
  border: none;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s;
  line-height: 100%;
  background-color: #007bff;
}

.filter-submit:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .search-filter-menu{
    flex-direction: column;
  }
}