/* 개선된 MobilityModelUpload.module.css */

:root {
  --main-bg-color: #ffffff;
  --main-text-color: #212529;
  --highlight-color: #007bff;
  --border-color: #dee2e6;
  --input-bg-color: #f8f9fa;
  --button-bg-color: #28a745;
  --button-hover-bg-color: #218838;
  --delete-button-bg-color: #dc3545;
  --delete-button-hover-bg-color: #c82333;
}

.containerStyle {
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.tabTitle,
.commonInfoTitle {
  color: var(--main-text-color);
  margin-bottom: 20px;
  font-weight: bold;
}

.formLabel {
  font-weight: 600;
  color: var(--main-text-color);
}

.formControl {
  background-color: var(--input-bg-color);
  border: 1px solid var(--border-color);
  color: var(--main-text-color);
  border-radius: 4px;
  padding: 10px;
}

.imageUploadSection {
  border: 2px dashed var(--highlight-color);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.imageUploadSection:hover {
  background-color: #f1f3f5;
}

.imagePreview {
  width: 150px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}

.imageThumbnail {
  border: 3px solid var(--highlight-color);
  width: 150px;
  height: 120px;
  object-fit: cover;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  border-radius: 4px;
}

.navTabsLink {
  color: var(--main-text-color) !important;
  background-color: #f8f9fa;
  border: 1px solid var(--border-color);
  margin-right: 4px;
  border-radius: 4px 4px 0 0;
  padding: 6px 10px;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s;
}

.navTabsLink:hover,
.navTabsLinkActive {
  border-color: var(--highlight-color);
  color: #fff !important;
  background-color: var(--highlight-color);
  font-weight: bold;
}

.addTabButton {
  color: var(--highlight-color) !important;
  background-color: #f8f9fa;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.addTabButton:hover {
  color: #0056b3 !important;
  background-color: #e9ecef;
}

.addFieldButton {
  margin-top: 10px;
  background-color: var(--button-bg-color);
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 140px;
}

.addFieldButton:hover {
  background-color: var(--button-hover-bg-color);
}

.deleteFieldButton {
  background-color: var(--delete-button-bg-color);
  color: #fff;
  border: none;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.deleteFieldButton:hover {
  background-color: var(--delete-button-hover-bg-color);
}

.customTabTitle {
  height: 100%;
  width: calc(100% - 40px);
  background-color: #f8f9fa;
  border: none;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  margin: 0;
}

#parameterForm ul li > button{
  padding : 0;
}