/* 메인 컨테이너 */
.main-container {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

/* 섹션 스타일 */
.section {
    /*border: 1px solid #ccc;*/
    padding: 10px;
    margin: 10px 0;
    background-color: white;
    border-radius: 10px;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
}

.main-carousel{
    height: 450px;
}

.main-carousel img{
    height: 400px;
    object-fit: cover;
    border-radius: 12px;
}



.brand-scroller {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* height: 120px;  // 필요시 고정 높이 설정 가능 */
    margin: 30px auto; /* 화면 가운데 배치 */
    padding: 10px 0;
    background: #fafafa; /* 배경색 (옵션) */
}

.brand-heading {
    text-align: center;
    margin-bottom: 20px;
}

.brand-heading h2 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 8px;
    /* 원하는 색상, 폰트 스타일 등 적용 */
}

.brand-heading p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0;
}

/* 브랜드 목록을 무한 슬라이드로 이어주는 부분 */
.brand-container {
    display: inline-flex;
    white-space: nowrap;
    animation: marquee 30s linear infinite;
    animation-play-state: running;
}

/* 브랜드 아이템 배치 */
.brand-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px; /* 아이템 간 간격 */
}

.brand-container img {
    width: 120px;
    height: 100px;
    object-fit: fill;
}

.brand-container p {
    margin-top: 8px;
    text-align: center;
    font-size: 0.9rem;
    color: #333;
}

/* 호버 시 애니메이션 멈춤 */
.brand-scroller:hover .brand-container {
    animation-play-state: paused;
}

/* marquee 애니메이션 (0%에서 0, 100%에서 -50%) */
@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}




/* 스크롤링 미디어 스타일 */
.scrolling-media {
    text-align: center;
    /*overflow-x: auto;*/
    /*white-space: nowrap;*/
}

.scrolling-media img {
    display: inline-block;
    width: 200px;
    height: auto;
    max-width: 100%;
    margin: 5px;
    border-radius: 5px;
}

/* 그리드 컨테이너 스타일 */
.grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* 그리드 아이템 스타일 */
.grid-item {
    padding: 15px;
    background-color: #f1f1f1;
    border-radius: 5px;
    text-align: center;
    max-width: 100%;
    overflow-wrap: break-word;
}

.grid-item.article{
    max-height: 400px;
    overflow-y: scroll;
    background-color: white;
}

.article-container{
    /*border: 1px solid #ccc;*/
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
    border-radius: 12px;
}

.article-container hr{
    margin: 0;
}

.article-container h2{
    margin: 5px 0px;
}
.article-image{
    width: 200px;
}
.article img{
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
}

.article span{
    font-size: 12px;
}

.article p{
    display: inline-block;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: auto;
    text-align: right;
}

.article a{
    color: black;
}

.article-box{
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 15px;
    background-color: #f1f1f1;
    border-radius: 12px;
    margin-bottom: 12px;
    transition-duration: 0.3s;
}

.article-box:hover{
    transform: scale(1.05);
}

.article-text{
    max-width: 300px;
    display: flex;
    flex-direction: column;
    /*align-content: center;*/
    /*margin: auto;*/
}

/* 🔹 about 섹션 스타일 */
.about-section {
    position: relative;
    background: url('/public/images/about-bg.jpg') center/cover no-repeat;
    margin-bottom: 20px;
    text-align: center;
    padding: 80px 20px;
    color: white;
    overflow: hidden;
    border-radius: 12px;
}

/* 🔹 배경에 어두운 오버레이 추가 */
.about-section .overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* 투명도 조절 */
}

/* 🔹 컨텐츠 정렬 */
.about-content {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    z-index: 2;
}

/* 🔹 제목 스타일 */
.about-content h2 {
    font-size: 36px;
    font-weight: bold;
}

/* 🔹 설명 문구 */
.about-content p {
    font-size: 18px;
    margin-top: 10px;
    color: #ddd;
}

/* 🔹 강조 표시 (신뢰도 높은 숫자) */
.about-content .highlight {
    color: #facc15; /* 노란색 강조 */
    font-weight: bold;
}

/* 🔹 신뢰도 숫자 강조 컨테이너 */
.stats-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

/* 🔹 개별 신뢰도 박스 */
.stats-box {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    min-width: 120px;
}

.stats-box h3 {
    font-size: 28px;
    color: #facc15; /* 노란색 강조 */
    font-weight: bold;
}

.stats-box p {
    font-size: 16px;
    color: #ddd;
}

/* 🔹 about More 버튼 */
.about-button {
    width: 200px;
    background: #facc15;
    color: black;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    margin: 20px;
    cursor: pointer;
    border: none;
    transition: background 0.3s;
}

.about-button:hover {
    background: #eab308;
}

/* 광고 아이템 스타일 */
.advertisement {
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
    color: #555;
    max-height: 314px;
    padding: 0;
    position: relative;
}

.advertisement img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}


/* 🔹 오버레이 기본 설정 (기본적으로 숨김) */
.ad-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* 투명한 검은색 배경 */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

/* 🔹 마우스를 올리면 오버레이 표시 */
.ad-overlay.show {
    opacity: 1;
}

/* 🔹 오버레이 안의 텍스트 */
.ad-overlay-text {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: black;
    border-radius: 12px;
    padding: 10px;
    transition-duration: 0.3s;
}

.ad-overlay-text:hover{
    transform: scale(1.05);
}

@media (max-width: 1600px) {
    .ad-container{
        display: none;
    }
}

/* 태블릿 및 작은 화면용 조정 */
@media (max-width: 768px) {
    .main-container {
        width: 100%;
    }

    .main-carousel{
        height: 350px;
    }

    .main-carousel img{
        height: 300px;
        object-fit: fill;
    }

    .scrolling-media img {
        width: 150px;
    }

    .stats-container {
        flex-direction: column;
    }

    .stats-box {
        margin-bottom: 10px;
    }

    .grid-container {
        grid-template-columns: 1fr; /* 모든 아이템을 한 열에 배치 */
        gap: 5px;
    }

    .section {
        padding: 15px;
        margin: 8px 0;
    }

    .map-section {
        font-size: 1em;
    }

    /* 혹시 이미지가 그리드 내부에 있을 경우 최대 너비 제한 */
    .grid-item img {
        width: 100%;
        height: auto;
        max-width: 100%;
    }

    .article-container{
        margin-bottom: 24px;
    }

    .article-image{
        width: 100%;
    }

    .article-box{
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 15px;
        background-color: #f1f1f1;
        border-radius: 12px;
        margin-bottom: 12px;
    }

    .article-text{
        display: flex;
        flex-direction: column;
        align-content: center;
        margin: auto;
    }
}

