/* 컨테이너 스타일 */
.formContainer {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    margin: 50px auto;
}

/* 제목 입력 필드 스타일 */
.formContainer h1 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

/*input {*/
/*    background-color: white;*/
/*    color: black;*/
/*    padding: 10px;*/
/*    width: 100%;*/
/*    border: 1px solid #ddd;*/
/*    border-radius: 5px;*/
/*    font-size: 16px;*/
/*    !*margin-bottom: 20px;*!*/
/*}*/

/* 버튼 스타일 */
/*button {*/
/*    background-color: #28a745;*/
/*    color: white;*/
/*    border: none;*/
/*    padding: 10px 20px;*/
/*    cursor: pointer;*/
/*    border-radius: 5px;*/
/*    font-size: 16px;*/
/*    !*width: 100%;*!*/
/*}*/

/*button:hover {*/
/*    background-color: #218838;*/
/*}*/

/* 카테고리 선택 드롭다운 스타일 */
.categorySelect {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: #ffffff;
}

/* 에디터 툴바 스타일 */
.toolbar {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
    flex-direction: row;
}

.toolbarButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.3s;
}

.toolbarButton:hover {
    background-color: #0056b3;
}

.toolbarButton svg {
    width: 16px;
    height: 16px;
}

/* 에디터 컨테이너 */
.editorContainer {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    min-height: 500px; /* 에디터 크기를 더 크게 설정 */
    background-color: #ffffff;
    color: #333;
    margin-bottom: 20px;
}

.editorContainer iframe,
.editorContainer img {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
}

/* 이미지와 동영상의 테두리와 관련된 추가 CSS */
.editorContainer img,
.editorContainer iframe {
    border: none; /* 검은색 테두리 제거 */
    box-shadow: none; /* 그림자 제거 */
}

/* 입력 필드 focus 시 스타일 */
input:focus,
textarea:focus,
select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
