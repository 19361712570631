.buyer-list-continer {
    position: relative;
    width: 100%;
    height: auto; /* Full screen height for Hero style */
    background-color: rgb(20, 69, 109); /* Darker background for more contrast */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    text-align: center;
    border-radius: 20px;
    margin: 20px;
}

.buyer-list-continer p {
    font-weight: 600;
}

.buyer-list-continer .country-tooltip {
    position: absolute;
    background: rgba(255, 255, 255, 0.9); /* Slightly transparent for better aesthetics */
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    pointer-events: none;
    font-size: 14px;
    color: #333;
    z-index: 10;
}

.buyer-list {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin:  auto;
}



.buyer-list .world-map {
    height: auto;
    border-radius: 8px; /* Rounded corners */
    overflow: hidden;
}

.buyer-list .buyer-info{
    display: flex;
    flex-direction: row;
    height: 50px;
    line-height: 50px;
    gap: 20px;
}

.buyer-list .buyer-info div {
    text-align: center;
}

.buyer-list .buyer-info  p {
    font-size: 24px; /* Increase font size for counters */
    font-weight: 700;
}

.buyer-list p {
    color: #ffffff;
    font-size: 28px;
}

.buyer-list span{
    color: #ffffff;
}

/* Highlighted first and last child styling */
.buyer-list div:first-child p {
    color: #ff6200; /* Highlight color for first counter */
}

.buyer-list div:last-child p {
    color: #4caf50; /* Highlight color for last counter */
}

.buyer-list-continer > p {
    margin-top: 20px;
    font-size: 20px;
    font-style: italic;
    color: #f0f0f0;
}


/* Container */
.signup-promo-container {
    width: 100%;
    height: 100vh; /* Full screen height */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #ffffff; /* 기본 텍스트 색상 */
    text-align: left;
}

/* Inner Content */
.signup-promo {
    max-width: 1200px;
    padding: 40px;
    background: rgba(0, 0, 0, 0.6); /* 어두운 반투명 배경 */
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/* Text Section */
.signup-text p {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 15px 0;
}

/* Buttons Section */
.signup-buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.signup-button {
    padding: 12px 12px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100px;
}

.signup-button.primary {
    background-color: #ff6200;
    color: #ffffff;
}

.signup-button.primary:hover {
    background-color: #e65500;
    transform: scale(1.05);
}

.signup-button.secondary {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #ffffff;
}

.signup-button.secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.05);
}


.counter {
    font-size: 5rem;
    font-weight: bold;
    color: #333;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Truck Container */
.truck-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full screen height */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Overlay for dark effect */
.truck-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Truck Content */
.truck-content {
    color: white;
    text-align: center;
    max-width: 800px;
    padding: 20px;
}

/* Truck Title */
.truck-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Truck Subtitle */
.truck-subtitle {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 30px;
}

/* Buttons */
.truck-buttons {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.truck-button {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.truck-button.primary {
    background-color: #ff6200;
    color: white;
}

.truck-button.primary:hover {
    background-color: #e65500;
}

.truck-button.secondary {
    background-color: white;
    color: #333;
    border: 2px solid #fff;
}

.truck-button.secondary:hover {
    background-color: #f2f2f2;
}
@media (max-width: 1200px) {
    .hero-container{
        display: none;
    }
}
