.footer {
  background-color: #333;
  color: #fff;
  padding: 10px 0; /* 기존 20px에서 10px로 줄임 */
  text-align: center;
  width: 100%;
}

.reduced-footer {
  font-size: 0.8em; /* 글자 크기 줄임 */
  padding: 5px 0; /* 패딩 줄임 */
}

.footer-content {
  display: flex;
  align-items: flex-start;
  padding: 10px 0; /* 기존 20px에서 10px로 줄임 */
  justify-content: center;
  gap: 50px;

}

.footer-item {
  max-width: 300px;
  /*flex:1*/
}

.footer-menu{
  text-align: left;
  display: flex;
  /*flex: 3;*/
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-category{
  width: auto;
}

.footer-category p{
  margin-bottom: 0;
}

.footer-category a{
  color: #cccccc;
}

.footer-category a:hover{
  color: rgb(255, 99, 71);
}

.footer-item h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #f8f8f8;
}

.footer-item-content {
  display: flex;
  flex-direction: column; /* 수직 정렬 */
  gap: 10px; /* 요소 간 간격 추가 */
}

.footer-item-content img{
  width: 32px;
  height: 32px;
}

.footer-item-content p {
  display: inline; /* 인라인 요소로 변경 */
  margin: 0 10px; /* 요소 간 간격 추가 */
  color: #ccc;
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #444;
  padding-top: 10px;
}

@media (max-width: 768px) {
  .footer-menu{
    display: none;
  }

  .footer-item{
    justify-content: center;
  }
}
