.core-features {
    background-color: #f8f9fa;
    padding: 80px 20px;
    text-align: center;
}

.core-features .container {
    max-width: 1000px;
    margin: 0 auto;
}

.core-features .title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
}

.core-features .subtitle {
    font-size: 18px;
    color: #555;
    margin-bottom: 40px;
}

.core-features .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.core-features .feature-card {
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.core-features .feature-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.core-features .icon {
    font-size: 40px;
    margin-bottom: 16px;
}

.core-features .handshake {
    color: #007bff;
}

.core-features .price {
    color: #28a745;
}

.core-features .car {
    color: #dc3545;
}

.core-features h3 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.core-features p {
    font-size: 16px;
    color: #666;
}
