.aboutContainer {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 40px 20px;
    font-family: "Arial", sans-serif;
    color: #333;
    background-color: #f8f9fa;
}

/* 헤더 */
.header {
    text-align: center;
    padding-bottom: 30px;
}

.logo {
    width: 120px;
    height: auto;
}

.header h1 {
    font-size: 2.5rem;
    margin-top: 10px;
    color: #2c3e50;
}

/* 소개 섹션 */
.intro {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
}

.textContent {
    flex: 1;
    min-width: 300px;
}

.textContent h2 {
    color: #1e3a8a;
    font-size: 2rem;
}

.textContent p {
    font-size: 1.1rem;
    line-height: 1.6;
    padding: 10px 0px;
}

.companyImage {
    width: 50%;
    max-width: 400px;
    border-radius: 10px;
    object-fit: cover;
}

/* 미션 섹션 */
.mission {
    text-align: center;
    padding: 40px 0;
    background-color: #ffffff;
}

.mission h2 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
}

.missionContent {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.missionBox {
    background: #e3f2fd;
    padding: 20px;
    border-radius: 10px;
    width: 45%;
}

.missionBox img{
    width: 80%;
    height: 300px;
    padding: 10px;
    border-radius: 20px;
}

.missionBox h3 {
    color: #1e3a8a;
    margin-bottom: 10px;
}

/* 파트너십 섹션 */
.partnership {
    text-align: center;
    padding: 40px 20px;
}

.partnership h2 {
    font-size: 2rem;
    color: #2c3e50;
}

.partnership p {
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
}

.partnership img {
    border-radius: 12px;
    width: 50%;
    padding: 5px;
}

.moreInfoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.moreInfo{
    display: inline-block;
    width: auto;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #4caf50;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 auto;
    line-height: 50px;
    padding: 0px 10px;
    color: black;
}

@media (max-width: 768px) {
    .companyImage{
        margin: auto;
        width: 80%;
    }

    .missionContent{
        flex-direction: column;
    }

    .missionBox{
        width: 100%;
    }

    .partnership img {
        width: 100%;
    }
}
