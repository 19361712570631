.gallery-container {
    width: 100%;
    text-align: center;
    padding: 20px;
}

.gallery-container video{
    width: 600px;
}

.gallery-title {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
}

.gallery-title::after {
    content: "";
    width: 60px;
    height: 3px;
    background-color: #ff5a5f; /* 포인트 색상 (원하는 색으로 변경 가능) */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
}


.gallery-description {
    font-size: 16px;
    color: #555;
    max-width: 600px;
    margin: 0 auto 20px;
    line-height: 1.5;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 15px;
    padding: 10px;
}

.gallery-item {
    width: 100%;
    max-width: 320px;
    max-height: 250px; /* 이미지의 최대 높이 제한 */
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    aspect-ratio: 4 / 3; /* 가로세로 비율 유지 */
    margin: auto;
}

.gallery-item:hover {
    transform: scale(1.05);
}

.gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .gallery-container video{
        width: 80%;
    }
}
