/* 전체 컨테이너 스타일 */
#contact-list-container {
    background-color: #f9f9f9; /* 부드러운 배경색 */
    padding: 20px; /* 내부 여백 */
    border-radius: 10px; /* 둥근 모서리 */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 */
    max-width: 900px; /* 적절한 최대 너비 */
    margin: 20px auto; /* 화면 중앙 정렬 */
}

/* 제목 스타일 */
#contact-list-container h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333; /* 제목 색상 */
    margin-bottom: 20px;
    text-align: center; /* 중앙 정렬 */
}

/* 데이터 행 스타일 */
#contact-list-container .row {
    background-color: white;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px 15px;
    transition: background-color 0.3s;
}

/* 열(헤더) 스타일 */
#contact-list-container .row:nth-child(2) {
    font-weight: bold;
    background-color: #007bff; /* 헤더 배경색 */
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 10px;
}

#contact-list-container .row:nth-child(2) .col{
    color: white; /* 헤더 텍스트 색상 */
    text-align: center;
}

/* 데이터 셀 스타일 */
#contact-list-container .col {
    text-align: center; /* 텍스트 중앙 정렬 */
    font-size: 0.95rem;
    color: #555; /* 부드러운 텍스트 색상 */
}

/* 마우스 오버 시 데이터 행 스타일 */
#contact-list-container .row:hover:nth-child(1n+1) {
    background-color: #f0f8ff; /* 연한 하이라이트 색상 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 약간의 그림자 */
}