.inquiry-form-container {
    width: 100%;
    max-width: 600px; /* 데스크톱에서 최대 너비를 600px로 제한 */
    margin: 0 auto;   /* 가운데 정렬 */
    padding: 20px;
}

.inquiry-form-container h2 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px;
}

/* ===== 입력 폼 전체 스타일 ===== */
.inquiry-form-container form {
    display: flex;
    flex-direction: column;
    gap: 16px; /* 각 섹션 간격 */
}

/* ===== 이름/이메일을 2컬럼으로 정렬하는 부분 ===== */
.form-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2열 레이아웃 */
    gap: 16px; /* 컬럼 사이 간격 */
}

/* 화면이 좁아지면 1열로 전환 */
@media (max-width: 576px) {
    .form-grid-container {
        grid-template-columns: 1fr; /* 한 줄로 변환 */
    }
}

/* 텍스트, 입력 요소 공통 스타일 */
.inquiry-form-container .text-start span {
    display: inline-block;
    margin-bottom: 6px;
    font-weight: bold;
}

/* input, textarea 기본 스타일 */
.inquiry-form-container input,
.inquiry-form-container textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box; /* 패딩 포함하여 width 계산 */
}

.inquiry-form-container textarea {
    height: 100px;
    resize: none;
}

.inquiry-form-container button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.inquiry-form-container button:hover {
    background-color: #0056b3;
}
