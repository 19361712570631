.profileContainer {
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.infoSection {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.label {
    font-weight: bold;
    width: 120px;
    color: #555;
    display: flex;
    align-items: center;
}

.profileImage {
    width: 120px;
    height: 120px;
}

.labelText {
    margin-left: 8px;
}

.value {
    color: #333;
    margin-left: 10px;
}

.input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 80%;
}

.select__control {
    min-width: 300px;
}

.select__option {
    cursor: pointer !important;
}

.select__option:hover {
    background-color: #e6e6e6 !important;
    color: #000 !important;
}

.buttonSection {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.editButton, .confirmButton, .cancelButton {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.editButton {
    background-color: #007bff;
    color: #fff;
}

.confirmButton {
    background-color: #28a745;
    color: #fff;
}

.cancelButton {
    background-color: #dc3545;
    color: #fff;
}

@media (max-width: 768px) {
    .profileContainer {
        max-width: 100%;
    }
}