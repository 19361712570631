/* 로딩 상태 */
.loading {
    text-align: center;
    font-size: 1.5rem;
    color: #777;
    margin-top: 50px;
}

/* 게시글을 찾을 수 없을 때 */
.notFound {
    text-align: center;
    font-size: 1.5rem;
    color: #e74c3c;
    margin-top: 50px;
}


/* 게시글 전체 컨테이너 */
.postDetail {
    background-color: #ffffff;
    padding: 20px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 800px;
    min-height: 100vh;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
}

/* 제목, 조회수 및 좋아요 수 상단에 배치 */
.postHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.postHeader div{
    display: flex;
    align-items: self-end;
    gap: 15px;
}

.postHeader button{
    width: 80px;
    font-size: 12px;
    padding: 5px 5px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #2c3e50;
    margin: 0;
}

.info {
    font-size: 0.9rem;
    color: #95a5a6;
    display: flex;
    gap: 15px;
}

/* 본문 내용 스타일 */
.content {
    font-size: 1.125rem;
    line-height: 1.8;
    color: #4a4a4a;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05);
    min-height: 300px;
    text-align: left;
}

/* 댓글 섹션 */
.commentSection {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.commentInput {
    flex: 1;
    min-height: 40px;
    max-height: 160px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;
    overflow-y: auto;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05);
}

.commentButton {
    padding: 8px 16px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    width: 80px;
}

.commentButton:hover {
    background-color: #2980b9;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* 댓글 목록 */
.commentList {
    margin-top: 20px;
    min-height: 150px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* 댓글이 없을 때 */
.noComments {
    text-align: center;
    font-size: 1rem;
    color: #888;
    padding: 20px;
}

/* 댓글 */
.comment {
    background-color: #f1f1f1;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
}
.commentHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.commentDate{
    font-size: 0.7rem;
    color: #2c3e50;
    text-align: left;
    padding-left: 12px;
    padding-top: 12px;
}

.commentAuthor {
    font-weight: bold;
    font-size: 0.9rem;
    color: #2c3e50;
    text-align: left;
    padding-top: 12px;
    padding-left: 24px;
}

.commentContent {
    margin-top: 5px;
    font-size: 0.95rem;
    color: #333;
    text-align: left;
    padding: 0px 24px;
    margin-bottom: 10px;
    overflow-wrap: anywhere;
}

.replyAuthor {
    font-weight: bold;
    font-size: 0.9rem;
    color: #2c3e50;
    text-align: left;
    padding-top: 12px;
    padding-left: 80px;
}

.replyDate{
    font-size: 0.7rem;
    color: #2c3e50;
    text-align: left;
    padding-left: 12px;
    padding-top: 12px;
}

.replyContent {
    margin-top: 5px;
    font-size: 0.95rem;
    color: #333;
    text-align: left;
    padding-left: 80px;
    margin-bottom: 20px;
}

.replyButton {
    font-size: 0.8rem;
    color: #3498db;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    margin-top: 5px;
    margin-left: auto;
    display: inline-block;
    width: 100px;
    justify-content: left;
}

.replyButton:hover {
    color: #2980b9;
}

/* 답글 영역 */
.reply{
}

.replySection {
    margin-top: 10px;
    padding-left: 40px;
}

.replyInput {
    width: calc(100% - 60px);
    height: 60px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;
}

.replyButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 5px;
}

.replyButtonSmall {
    padding: 5px 10px;
    font-size: 0.85rem;
    color: white;
    background-color: #3498db;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 60px;
}

.replyButtonSmall:hover {
    background-color: #2980b9;
}

.cancelReplyButton {
    padding: 5px 10px;
    font-size: 0.85rem;
    color: white;
    background-color: #95a5a6;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 60px;
}

.cancelReplyButton:hover {
    background-color: #7f8c8d;
}

/* 좋아요 버튼 섹션 */
.likeButtonSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.likeButton {
    background-color: cornflowerblue;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #ffffff;
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    gap: 5px;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.likeButton:hover {
    background-color: #c0392b;
    transform: scale(1.05);
}

.postDetail .content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}



.replyToggleButton {
    background-color: transparent;
    width: 100px;
    font-size: 12px;
    margin-left: 16px;
    padding: 5px 5px 5px 5px;
    color: #065fd4;
    border-radius: 18px;
    border: none;
}

.replyToggleButton:hover {
    background-color: #def1ff;
    border-color: transparent;
}

.replyToggleButtonBox {
    display: flex;
}

.postList{
    margin-top: 15px;
}

.postList h4{
    font-size: 16px;
    font-weight: 700;
    padding: 3px;
}
