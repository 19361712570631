.counter-container {
    text-align: center;
}

.counter {
    font-weight: bold;
    color: whitesmoke;
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
