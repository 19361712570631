.global-partners-container {
    max-width: 1200px;
    margin: auto;
}

.header-image {
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    margin-top: 20px;
}

.header-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 12%;
}

.header-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 15px 30px;
    border-radius: 10px;
}

.header-overlay h1 {
    font-size: 26px;
    font-weight: bold;
}

.header-overlay p {
    font-size: 18px;
}


/* 대륙 컨테이너 */
.continent-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin: 20px auto;
    width: 80%;
}

.continent {
    width: 160px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    border: 2px solid #333;
    transition: all 0.3s ease-in-out;
}

.continent:hover {
    background-color: #7e7e7e;
    color: white;
}

.continent.selected {
    background-color: #333;
    color: white;
    border-color: #222;
}

/* 검색창 스타일 */
.search-container {
    display: flex;
    justify-content: end;
    margin: auto;
    width: 80%;
}

.search-container input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #aaa;
    border-radius: 8px;
    outline: none;
    transition: 0.3s;
}

.search-container input:focus {
    border-color: #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}


/* 국가 컨테이너 */
.country-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: auto;
    padding: 10px;
    text-align: center;
    width: 80%;
    max-height: 250px;
    overflow-y: auto;
    border: 2px solid #aaa;
    background-color: white;
    border-radius: 10px;
}

.country {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    background-color: white;
    border: 2px solid #555;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.country:hover {
    background-color: #6e6e6e;
    color: white;
}

.country.selected {
    background-color: #333;
    color: white;
    border-color: #222;
}

.selected-country {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #444;
    background: linear-gradient(135deg, #e0e0e0, #ffffff);
    padding: 12px 20px;
    margin: 20px auto;
    width: 90%;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeIn 0.6s forwards;
}

/* 선택된 국가명 호버 효과 */
.selected-country:hover {
    background: linear-gradient(135deg, #d0d0d0, #f8f8f8);
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* 페이드 인 애니메이션 */
@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 바이어 목록 */
.buyer-container {
    margin: 30px auto;
    width: 90%;
    /*background-color: white;*/
    padding: 20px;
    border-radius: 10px;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
}

.buyer-container table {
   width: 100%;
    border-collapse: collapse;
    text-align: center;
    margin-bottom: 15px;
}

.buyer-container th,
.buyer-container td {
    overflow: auto;
    padding: 10px;
    border: 1px solid #ddd;
    max-width: 200px;
}

.buyer-container thead {
    background-color: #333;
    color: white;
}

.buyer-container tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.buyer-container tbody tr:hover {
    background-color: #ddd;
    /*cursor: pointer;*/
}

@media (max-width: 768px) {
    .global-partners-container{
        width: 100%;
    }
    .header-image{
        width: 100%;
    }
    .header-overlay{
        top: 35%;
    }

    .buyer-container{
        overflow: scroll;
    }
    .buyer-container {
        margin: 30px auto;
        width: 100%;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .buyer-container table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        margin-bottom: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .buyer-container th,
    .buyer-container td {
        overflow: auto;
        border: 1px solid #ddd;
        max-width: 200px;
    }

    .buyer-container thead {
        background-color: #333;
        color: white;
    }

    .buyer-container tbody tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .buyer-container tbody tr:hover {
        background-color: #ddd;
        /*cursor: pointer;*/
    }
}
