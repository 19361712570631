/* 컨테이너 스타일 */
.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}
.bi-search{
    width: 20px;
    height: 20px;
}
/* 텍스트 중앙 정렬 */
.textCenter {
    text-align: center;
    color: #333;
}

/* margin bottom 4 */
.mb4 {
    margin-bottom: 1.5rem;
}

/* 카테고리 리스트 스타일 */
.categoryList {
    margin-bottom: 20px;
    max-width: 1000px;
}

.categoryList select{
    height: 30px;
    padding: 3px;
    text-align: center;
    width: auto;
}

.postMenu{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* 검색바 스타일 */
.searchContainer {
    display: flex;
    gap: 5px;
    justify-content: center;
    /*width: 40%;*/
    max-width: 800px;
}

.searchContainer select {
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 12px;
    padding: 0;
    text-align: center;
    width: 50px;
}

.searchContainer input {
    height: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.searchContainer button {
    height: 30px;
    width: 30px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 5px;
    background-color: #7f8c8d;
}

.searchContainer button:hover {
    background-color: #cccccc;
}

/* 글쓰기 버튼 스타일 */
.writeButton {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.2s;
    align-self: flex-end;
    font-size: 18px;
    cursor: pointer;
}

.writeButton:hover {
    background-color: #218838;
}
.postList {
    list-style: none;
    padding: 0;
    width: 100%; /* 글 목록의 너비를 전체에 맞춤 */
}

.postItem {
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 5px 5px;
    transition: box-shadow 0.2s;
    width: 100%; /* 글 목록의 너비를 전체에 맞춤 */
    box-sizing: border-box; /* 패딩을 포함한 너비 계산 */
    overflow: hidden;
}

.postItem:hover {
    background-color: #eeeeee;
}

.postInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.postInfoLeft {
    display: flex;
    gap: 10px; /* 카테고리와 제목 사이에 간격 */
    align-items: center;
    text-align: left;
    width: 60%;
}

.postInfoRight {
    display: flex;
    gap: 10px; /* 오른쪽 요소들 사이 간격 */
    align-items: center;
    text-align: center;
    width: 40%;
}
.postTitle {
    font-size: 1rem;
    color: #666;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 15px;
    width: 80%;
    text-align: center;
}

.postInfoLeft a:hover{
    text-decoration: underline;
}


.postAuthor, .viewCount  {
    font-size: 13px;
    color: #666;
    width: 90px;
}

.postDateHeader{
    font-size: 13px;
    width: 20%;
}

.likeCount {
    font-size: 0.9rem;
    color: #666;
    width: 15%;
}

.likeIcon {
    margin-right: 5px;
    color: #ff4500;
}

.emptyPostItem {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #ddd;
    margin-bottom: 8px;
    border-radius: 5px;
    color: #666;
}

.postCategory {
    font-size: 0.9rem;
    color: #666;
}

/* 게시글 목록 헤더 스타일 */
.postListHeader {
    /*background-color: #f1f1f1;*/
    padding: 10px 15px;
    margin-bottom: 10px;
    font-weight: bold;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 100%; /* 글 목록과 너비를 맞춤 */
    box-sizing: border-box; /* 패딩을 포함한 너비 계산 */
    overflow: hidden;
}

.postCountry {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    color: #666;
    min-width: 40px;
}

.flagIcon {
    width: 16px;
    height: 12px;
    border-radius: 2px;
}

@media (max-width: 768px) {
    .contentContainer{
        padding: 5px;
    }
    .writeButton{
        font-size: 12px;
        width: auto;
    }
    .postInfoLeft {
        width: 70%;
    }

    .postInfoRight {
        width: 30%;
    }
    .postAuthor,.viewCount,.postDateHeader,.postDate{
        display: none;
    }

    .likeCount,.postCountry{
        width: 50%;
    }
    .postInfoLeft{
        overflow: hidden;
    }
}