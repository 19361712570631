/* YourComponent.module.css */
#login-modal .form-control{
}

.centerAlign {
    display: flex;
    justify-content: center;
}


