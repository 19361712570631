#navbar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(51, 51, 51, 0.8);
    color: #fff;
    z-index: 100;

}

.navbar-container{
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.nav-content{
    display: flex;
    flex-direction: row;
    gap: 40px;
}


.bi-list{
    display: none;
}

#navbar a{
    color: #fff;
}

.nav-left,.nav-right{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-left{
    margin-left: 10%;
    gap: 40px;
    flex-grow: 2;
}

.nav-right{
    justify-content: end;
    margin-right: 10%;
    gap: 20px;
    flex-grow: 1;
}

.nav-logo{
    display: inline-block;
    width: 180px;
    font-size: 1.5rem;
    line-height: 60px;
    font-weight: 800;
    cursor: pointer;
}

.nav-logo img{
    width: 60px;
    height: 60px;
}

#navbar .nav-link{
    position: relative;
    z-index: 2;
    font-size: 1rem;
    font-weight: 700;
    line-height: 60px;
    cursor: pointer;
    max-width: 180px;
}

#navbar a:hover,#navbar .nav-link:hover{
    color: #ff6347
}

#navbar .nav-dropdown .dropdown-content{
    position: absolute;
    background-color: rgba(110, 110, 110, 0.9);
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    top: 60px;
    left: 0;
    z-index: -1;
    transition: 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    gap: 30px;
}

#navbar .dropdown-category{
    display: inline-block;
    max-width: 220px;
    min-width: 60px;
    padding: 10px;
    text-align: left;
}

#navbar .nav-dropdown .dropdown-content a{
    display: block;
    width: 100%;
    padding: 3px 5px;
    line-height: 20px;
}

.dropdown-category hr{
    margin: 5px 0px;
}

#navbar .category-title{
    color: #1d1d1d;
    width: 150px;
    font-weight: 700;
}

.nav-login,.nav-lng{
    position: relative;
    text-align: center;
    background-color: #6e6e6e;
    border-radius: 10px;
    max-width: 150px;
    width: auto;
    height: 40px;
    padding: 5px 20px;
    cursor: pointer;
    margin-left: auto;
}

.nav-login:hover,.nav-lng:hover{
    background-color: #838383;
}

.nav-login .user-menu{
    position: absolute;
    left: 0px;
    top: 40px;
    max-height: 0px;
    overflow: hidden;
    border-radius: 10px;
}

.nav-login .user-menu.open{
    max-height: 100vh;
}

.nav-login .user-menu a{
    background-color: rgba(110,110,110,0.9);
    display: block;
    padding: 5px 15px;
    width: 120px;
}

.nav-login .user-menu a:hover{
    background-color: #7e7e7e;
    color: #ff6347;
}

#navbar .lng-dropdown{
    position: absolute;
    max-height: 0px;
    overflow: hidden;
}

#navbar .lng-dropdown.open{
    border-radius: 15px;
    max-height: 100vh;
}

#navbar .lng-element{
    background-color: rgb(110,110,110,0.9);
    padding: 5px;
    width: 200px;
    height: 40px;
    cursor: pointer;
    font-size: 14px;
}

#navbar .lng-element:hover{
    background-color: #8e8e8e;
}

.mobile-navbar{
    display: none;
}

@media (min-width: 1025px) and (max-width: 1440px){
    .navbar-container{
        width: 100%;
        gap: 15px;
    }
    .nav-content{
        gap: 40px;
    }
}

@media (min-width: 769px){
    #navbar .nav-dropdown:hover .dropdown-content{
        max-height: 400px;
        padding-bottom: 20px;
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    .navbar-container{
        width: 100%;
    }
    .nav-left {
        gap: 10px;
    }
    .nav-right {
        gap: 10px;
    }
    .nav-content{
        gap: 20px;
    }
}

@media (max-width: 768px){
    .navbar-container{
        width: 100%;
    }

    .nav-logo{
        font-size: 1rem;
    }


    .mobile-navbar{
        display: none;
        position: absolute;
        right: 0px;
        top: 60px;
        width: 280px;
        background-color: #7e7e7e;
        height: auto;
        max-height: 100vh;
    }

    .mobile-navbar.open{
        display: inline-block;
        overflow: auto;
    }

    .nav-lng, .nav-login{
        width: 40px;
        padding: 5px 0px;
    }

    .nav-login span,.nav-lng span{
        display: none;
    }

    #navbar ul{
        display: block;
        padding: 0px;
        list-style: none;
        margin: 0px;
    }

    #navbar ul, #navbarli {
        text-align: left;
    }

    /*li a {*/
    /*    display: block;*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/

    #navbar ul a{
        display: block;
        width: 100%;
        line-height: 50px;
        padding-left: 10px;
        font-weight: 700;
        border-bottom: 0.1px solid whitesmoke;
        box-sizing: border-box;
    }

    #navbar ul li:not(:nth-child(1)){
        overflow: hidden;
        max-height: 0px;
        transition: 0.1s all;
    }

    #navbar ul li.open:not(:first-child){
        display: list-item;
        max-height: 100%;
        min-height: 50px;
        line-height: 50px;
        background-color: #6e6e6e;
        padding-left: 20px;
        font-size: 14px;
    }

    .bi-list{
        display: inline;
        font-size: 32px;
        text-align: right;
        /*margin-left: auto;*/
        cursor: pointer;
        margin-right: 10px;
    }

    #navbar .nav-content{
        display: none;
        position: absolute;
        top: 60px;
        right: 0px;
        background-color: #6e6e6e;
        width: 200px;
        border-radius: 12px;
    }

    #navbar .nav-content.open{
        display: flex;
        flex-direction: column;
    }
    #navbar .lng-dropdown{
        right: 40px;
    }
}