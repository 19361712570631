#car-item {
    background-color: var(--theme-light);
    border: 1px solid var(--theme-dark);
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 230px;
    /*padding: 10px;*/
}

#car-item a {
    text-decoration: none;
}

#car-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

#car-item .car-item-image {
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid var(--theme-dark);
}

#car-item .card-body{
    display: flex;
    flex-direction: column;
    /*position: relative;*/
    /*padding: 5px;*/
    height: 120px;
}

#car-item .card-title {
    font-size: 13px;
    color: var(--theme-dark);
    font-weight: bold;
    text-decoration: none;
}

#car-item .card-text {
    display: inline-block;
    font-size: 13px;
    color: var(--theme-dark);
    text-decoration: none;
}

#car-item .card-meta{
    width: 100%;
    /*position: absolute;*/
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding: 0px 5px;
}

#car-item .card-meta p{
    margin-bottom: 0px;
}


#car-item .subDesc {
    text-align: left;
}

@media (max-width: 768px) {
    #car-item{
        width: 100%;
    }

    #car-item .card-body{
        height: 110px;
    }
}
