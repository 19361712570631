/* 검색창 */
/* 전체 컨테이너를 화면 중앙에 배치 */
.search-container {
    display: flex;
    flex-direction: column; /* 로고 위, 검색창 아래 */
    align-items: center;
    margin-top: 100px;
    max-width: 90%;
}

/* 검색 박스(테두리, 둥근 모서리 등) */
.search-box {
    display: flex;
    align-items: center;
    width: 500px;              /* 적당한 고정 너비 */
    max-width: 80%;            /* 화면이 좁아지면 90%까지 줄임 */
    border: 1px solid #ddd;    /* 기본 테두리 */
    border-radius: 30px;       /* 둥근 모서리 (Google 스타일) */
    padding: 5px 10px;
    transition: border-color 0.3s;
}

/* 자식 중 하나라도 포커스되면 테두리 강조 */
.search-box:focus-within {
    border-color: #6e6e6e;
}

/* 검색창(입력 필드) 스타일 */
.search-box input {
    flex: 1;                   /* 남은 공간을 꽉 채움 */
    border: none;
    outline: none;
    font-size: 16px;
    padding: 8px 10px;
    color: #6e6e6e;
    background: transparent;   /* 투명 배경 */
}

/* 검색 버튼 */
.search-box button {
    background-color: #6e6e6e; /* 그레이 톤 */
    color: #fff;
    border: none;
    border-radius: 30px;       /* 둥근 모서리 */
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.search-box button:hover {
    background-color: #555;    /* 살짝 더 어두워짐 */
}

@media (max-width: 768px) {
    .search-box{
        /*justify-content: left;*/
    }
}