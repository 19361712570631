:root {
    --theme-background: #f8f8f8;
    --theme-primary: #d39a58;
    --theme-secondary: #b19db4;
    --theme-accent: #e76d7e;
    --theme-dark: #5a5770;
    --theme-light: #ffffff;
}

body {
    /*padding-top: 60px;*/
    font-family: "Roboto", sans-serif;
    margin: 0;
}

.App {
    /*text-align: center;*/
    display: flex;
    flex-direction: column;
    width: 100%;
}

a {
    text-decoration: none;
}
#container{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.content-container {
    margin: 0 auto;
    width: 1400px;
    display: flex;
    flex-direction: row;
    padding-top: 60px;
    min-height: 90vh;
}

.ad-container{
    position: relative;
    text-align: center;
    width: 200px;
    max-height: 100%;
    margin-top: 140px;
}

.ad-content{
    position: sticky;
    width: 200px;
    height: 600px;
    background-color: #f7f9fb;
    line-height: 100%;
    cursor: default;
    color: #b5a8ad;
    top: 100px;
}
.carousel-control-next-icon,.carousel-control-prev-icon{
    background-color: black;

}

.carousel-control-next,.carousel-control-prev{
    margin: auto;
    height: 30%;
}
/*.content-container .left,.right {*/
/*    flex-grow: 1;*/
/*    background-color: #2e312e;*/
/*    color: whitesmoke;*/
/*    height: 600px;*/
/*    position: sticky;*/
/*    top:15%;*/
/*}*/

/* 에디터 툴바 스타일 */
.toolbar {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
    flex-direction: row;
}

.toolbarButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.3s;
}

.toolbarButton:hover {
    background-color: #0056b3;
}

.toolbarButton svg {
    width: 16px;
    height: 16px;
}

/* 에디터 컨테이너 */
.editorContainer {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    min-height: 500px; /* 에디터 크기를 더 크게 설정 */
    background-color: #ffffff;
    color: #333;
    margin-bottom: 20px;
}

.editorContainer iframe,
.editorContainer img {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
}

/* 이미지와 동영상의 테두리와 관련된 추가 CSS */
.editorContainer img,
.editorContainer iframe {
    border: none; /* 검은색 테두리 제거 */
    box-shadow: none; /* 그림자 제거 */
}

/* 입력 필드 focus 시 스타일 */
input:focus,
textarea:focus,
select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* 전체 스크롤바 스타일 */
::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 */
    height: 8px; /* 가로 스크롤바 높이 */
}

/* 스크롤바 트랙 (배경) */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* 연한 회색 배경 */
    border-radius: 10px; /* 둥근 모서리 */
}

/* 스크롤바 핸들 (움직이는 부분) */
::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, #7f8c8d, #6e6e6e); /* 그라디언트 효과 */
    border-radius: 10px;
}

/* 스크롤바 호버 효과 */
::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(135deg, #0056b3, #0096c7); /* 좀 더 어두운 블루 */
}

select {
    /*appearance: none;  !* 기본 UI 숨기기 *!*/
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
}

select:focus {
    border-color: #6e6e6e;
    box-shadow: 0 0 10px rgba(110, 110, 110, 0.5);
}

.custom-pagenation {
    display: flex;
    flex-direction: row;
    justify-content: center;
}



.model-description img {
    width: 100%;
}

.m-auto {
    margin: auto;
}

.mt-1 {
    margin-top: 10px;
}

.w-100 {
    width: 100%;
}

.flex {
    display: flex;
}

.f-col {
    flex-direction: column;
}

.f-row {
    flex-direction: row;
}

@media (max-width: 768px) {
    .content-container{
        width: 100%;
    }
}