/* 컨테이너 스타일 */
.contentContainer {
    width: 100%;
}
.postList {
    list-style: none;
    padding: 0;
    width: 100%; /* 글 목록의 너비를 전체에 맞춤 */
}

.postItem {
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    padding: 5px 5px;
    transition: box-shadow 0.2s;
    width: 100%; /* 글 목록의 너비를 전체에 맞춤 */
    box-sizing: border-box; /* 패딩을 포함한 너비 계산 */
    overflow: hidden;
}

.postItem.view{
    background-color: #cccccc;
}

.postItem:hover {
    background-color: #eeeeee;
}

.postInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.postInfoLeft {
    display: flex;
    gap: 5px; /* 카테고리와 제목 사이에 간격 */
    align-items: center;
    text-align: left;
    width: 60%;
}

.postInfoRight {
    display: flex;
    gap: 5px; /* 오른쪽 요소들 사이 간격 */
    align-items: center;
    text-align: center;
    width: 40%;
}

.postCategory {
    font-size: 14px;
    color: #666;
    flex: 1;
}

.postTitle {
    font-size: 14px;
    color: #666;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 15px;
    text-align: center;
    flex: 3;
}

.postInfoLeft a:hover{
    text-decoration: underline;
}


.postAuthor, .viewCount  {
    font-size: 13px;
    color: #666;
    width: 90px;
}

.postDate{
    font-size: 11px;
    width: 20%;
}

.likeCount {
    font-size: 14px;
    color: #666;
    width: 15%;
}

.likeIcon {
    margin-right: 5px;
    color: #ff4500;
}

.emptyPostItem {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #ddd;
    margin-bottom: 8px;
    border-radius: 5px;
    color: #666;
}


.postCountry {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: #666;
    width: 15%;
}

.flagIcon {
    width: 16px;
    height: 12px;
    border-radius: 2px;
}

@media (max-width: 768px) {
    .postAuthor,.viewCount,.postDateHeader,.postDate{
        display: none;
    }

    .likeCount,.postCountry{
        width: 50%;
    }
    .postInfoLeft {
        width: 70%;
    }

    .postInfoRight {
        width: 30%;
    }
    /*.postInfoLeft{*/
    /*    overflow: hidden;*/
    /*}*/

}