/* contact-page는 그대로 사용 */
.contact-page {
    display: flex;
    flex-direction: row;
    align-items: center; /* Info가 상단 정렬되도록 조정 */
    gap: 40px; /* 컨텍트 폼과 정보 사이 여백 주기 */
    padding: 20px;
}

/* 기본 구조 */
.contact-info {
    width: 100%;
    max-width: 400px; /* 너무 넓지 않게 제한 */
}

/* 카드 형태로 묶은 컨테이너 */
.contact-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* 각 라인 디자인 */
.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

/* 라벨과 값 사이 간격 */
.contact-label {
    font-weight: 600;
    margin-right: 8px;
}

/* 값 스타일 */
.contact-value {
    color: #333;
}

/* 국기 플래그와 라벨 사이 간격 */
.contact-item img,
.contact-item svg {
    margin-right: 8px;
    width: 32px;
    height: 32px;
}

/* 반응형 */
@media (max-width: 768px) {
    .contact-page {
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }

    .contact-info {
        max-width: 100%;
    }
}
